.section-title {
  border-bottom: 0.5px solid #4e4e4e; /* Ajoute une bordure inférieure de 0.5px solide */
  padding: 40px 0; /* Espace entre le texte et la bordure */
  margin: 0 auto 40px; /* Centre horizontalement avec marge inférieure de 20px */
  width: 50%; /* Définit la largeur à 50% de son conteneur */
}

@media (max-width: 600px) {
 .section-title {
  border-bottom: 0.5px solid #4e4e4e; /* Ajoute une bordure inférieure de 0.5px solide */
  padding: 30px 0; /* Espace entre le texte et la bordure */
  margin: 0 auto 30px; /* Ajoute une marge supérieure de 10px, centre horizontalement avec marge inférieure de 40px */
  font-size: 2em; /* Ajustez la taille de la police selon vos préférences */
  width: 70%; /* Définit la largeur à 50% de son conteneur */
}
}