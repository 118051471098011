/* Typographie pour h1, h2, et h3 */
h1, h2, h3, p, text-small {
  font-family: 'Lora', serif; /* Utiliser la même typo pour les 3 */
  color: #000000c9; /* Couleur du texte */
  margin: 15px 0; /* Espacement vertical */
  font-weight: normal; /* Poids de police normal pour tous les éléments */

}
/* Taille de h1 (le plus grand) */
h1 {
  font-size: 2.6rem;
}
/* Taille de h2 (plus petit que h1) */
h2 {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 300;
}
/* Taille de h3 (plus petit que h2) */
h3 {
  font-size: 1.9rem;
}
/* Styles pour les paragraphes */
p {
  font-size: 1rem; /* Taille de police pour les paragraphes */
}
/* Styles pour les autres éléments de texte */
text-small {
  font-size: 1rem; /* Taille de police pour le texte petit */
}
.text-large {
  font-size: 1.125rem; /* Taille de police pour le texte grand */
}

@media (max-width: 768px) {
  h2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  h3 {
    font-size: 1.2rem;
  }
}
