.header-title {
  font-size: 3rem; /* Taille par défaut */
}

.background-photo {
  background-size: cover;
  background-position: center;
  height: 100vh; /* Ajustez la hauteur selon vos besoins */
  position: relative;
  z-index: 1; /* Assurez-vous que l'arrière-plan est en arrière-plan */
  width: 100%; /* Assurez-vous que l'arrière-plan prend toute la largeur */
  overflow: hidden; /* Évitez le débordement */
}

.white-band {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.3); /* Ajustez l'opacité selon vos besoins */
  z-index: 3; /* Assurez-vous que la white band est au-dessus du voile */
  width: 100%; /* Assurez-vous que la white band prend toute la largeur */
  box-sizing: border-box; /* Incluez le padding dans la largeur */
  position: absolute; /* Positionnez la bande blanche en bas */
  bottom: 0; /* Positionnez la bande blanche en bas */
}

.text-left {
  text-align: center;
  margin-bottom: 10px;
  z-index: 4; /* Assurez-vous que le texte est au-dessus du voile */
}


.sections {
  display: flex;
  justify-content: center; /* Centre les éléments horizontalement */
  gap: clamp(70px, 1%, 17%); /* Espacement responsive */
  z-index: 4;
  text-align: center; /* Centre le texte */
  transition: gap 0.3s ease;
}
.sections.open {
  display: block;
}

.social-icons {
  display: flex;
  justify-content: flex-end;
  z-index: 4; /* Assurez-vous que les icônes sociales sont au-dessus du voile */
}

.social-icons img {
  width: 30px;
  margin: 0 10px;
}

.menu-icon {
  display: none;
  z-index: 4; /* Assurez-vous que l'icône du menu est au-dessus du voile */
}

.menu-icon img {
  width: 30px;
}

/* Styles pour le hamburger menu */
.hamburger {
  width: 30px;
  height: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  z-index: 4; /* Assurez-vous que le hamburger est au-dessus du voile */
  transition: transform 1s ease; /* Transition pour l'icône */

}

.hamburger span {
  display: block;
  height: 3px;
  background: #333;
  border-radius: 3px;
  transition: all 0.3s ease;
  width: 100%;
}

.hamburger.open span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Styles pour les titres cliquables */
.clickable {
  cursor: pointer; /* Change le curseur en main (pointer) */
}

/* Styles pour les petits écrans */
@media (max-width: 768px) {
  .background-photo {
    height: 100vh; /* Prend toute la hauteur de l'écran */
    max-height: 100vh; /* Ne dépasse pas la hauteur de l'écran */
    background-size: cover; /* Couvre toute la zone sans déformation */
    background-position: center; /* Centre l'image de fond */
  }
  .white-band {
    flex-direction: column;
    padding: 10px;
    width: 100%; /* Assurez-vous que la white band prend toute la largeur */
    box-sizing: border-box; /* Incluez le padding dans la largeur */
    position: static; /* Positionnez la bande blanche en haut pour les petits écrans */
  }
  .text-left {
    text-align: center;
    margin-bottom: 20px;
  }
  .text-left h1 {
    font-size: 2rem; /* Ajuster la taille pour les petits écrans */
  }
  .text-left h2 {
    font-size: 1.2rem; /* Ajuster la taille pour les petits écrans */
  }
  .sections {
    opacity: 0;
    transform: translateY(-20px);
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: opacity 0.3s ease, transform 0.3s ease, max-height 0.3s ease;
  }
  
  .sections.open {
    opacity: 1;
    transform: translateY(0);
    max-height: 500px; /* Ajustez cette valeur en fonction du contenu */
  }
  
  .menu-icon {
    display: block;
    margin-bottom: 20px;
  }
  
  .social-icons {
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .social-icons img {
    width: 20px;
  }
  
}

@media (max-width: 1570px) and (min-width: 1200px) {
  .sections {
    gap: 8%;
  }
  
  .sections h3 {
    font-size: 1.3rem;
  }
  .header-title {
    font-size: 2.5rem;
  }
}

/* Écrans moyens (900px - 1199px) */
@media (max-width: 1199px) and (min-width: 900px) {
  .sections {
    gap: 2%;
  }
  
  .sections h3 {
    font-size: 1rem;
  }
  .header-title {
    font-size: 2rem;
  }
}

/* Petits écrans (768px - 899px) */
@media (max-width: 899px) and (min-width: 768px) {
  .sections {
    gap: 4%;
  }
  
  .sections h3 {
    font-size: 1.1rem;
  }
  .header-title {
    font-size: 3rem; /* Taille par défaut */
  }
}

.fuzzy-overlay {
  position: absolute;
  inset: -1000%;
  background-image: url("../image/noise.png");
  opacity: 0.05;
  z-index: 0;
  animation: shift 0.4s linear infinite both;
}

@keyframes shift {
  0% {
      transform: translateX(10%) translateY(10%);
  }
  100% {
      transform: translateX(-10%) translateY(-10%);
  }
}