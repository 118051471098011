

@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  6.25% {
    box-shadow: 0 0 11px rgba(63, 0, 0, 0.55); /* Ombre rouge intermédiaire */
  }
  12.5% {
    box-shadow: 0 0 12px rgba(127, 0, 0, 0.6); /* Ombre rouge intermédiaire */
  }
  18.75% {
    box-shadow: 0 0 13px rgba(191, 0, 0, 0.65); /* Ombre rouge intermédiaire */
  }
  25% {
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.7); /* Ombre rouge */
  }
  31.25% {
    box-shadow: 0 0 15px rgba(191, 63, 0, 0.7); /* Ombre orange intermédiaire */
  }
  37.5% {
    box-shadow: 0 0 15px rgba(127, 127, 0, 0.7); /* Ombre jaune intermédiaire */
  }
  43.75% {
    box-shadow: 0 0 15px rgba(63, 191, 0, 0.7); /* Ombre vert clair intermédiaire */
  }
  50% {
    box-shadow: 0 0 15px rgba(0, 255, 0, 0.7); /* Ombre verte */
  }
  56.25% {
    box-shadow: 0 0 14px rgba(0, 191, 63, 0.65); /* Ombre vert intermédiaire */
  }
  62.5% {
    box-shadow: 0 0 12px rgba(0, 127, 127, 0.6); /* Ombre cyan intermédiaire */
  }
  68.75% {
    box-shadow: 0 0 11px rgba(0, 63, 191, 0.55); /* Ombre bleu clair intermédiaire */
  }
  75% {
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.7); /* Ombre bleue */
  }
  81.25% {
    box-shadow: 0 0 10px rgba(63, 0, 191, 0.65); /* Ombre violet intermédiaire */
  }
  87.5% {
    box-shadow: 0 0 10px rgba(127, 0, 127, 0.6); /* Ombre magenta intermédiaire */
  }
  93.75% {
    box-shadow: 0 0 10px rgba(191, 0, 63, 0.55); /* Ombre rose intermédiaire */
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}
@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: translateY(0);
  }
  6.25% {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.55);
    transform: translateY(-0.375px);
  }
  12.5% {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.6);
    transform: translateY(-0.75px);
  }
  18.75% {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.65);
    transform: translateY(-1.125px);
  }
  25% {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    transform: translateY(-1.5px);
  }
  31.25% {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.75);
    transform: translateY(-1.875px);
  }
  37.5% {
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.8);
    transform: translateY(-2.25px);
  }
  43.75% {
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.85);
    transform: translateY(-2.625px);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
    transform: translateY(-3px);
  }
  56.25% {
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.85);
    transform: translateY(-2.625px);
  }
  62.5% {
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.8);
    transform: translateY(-2.25px);
  }
  68.75% {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.75);
    transform: translateY(-1.875px);
  }
  75% {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    transform: translateY(-1.5px);
  }
  81.25% {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.65);
    transform: translateY(-1.125px);
  }
  87.5% {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.6);
    transform: translateY(-0.75px);
  }
  93.75% {
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.55);
    transform: translateY(-0.375px);
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: translateY(0);
  }
}
.popup-content {
  animation: shadowPulse 3s ease-in-out infinite; /* Apply the animation with a smoother transition */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000; /* Assurez-vous que le popup est au-dessus des autres éléments */
}

/* Styles pour les petits écrans */
@media (max-width: 768px) {
  .popup-content {
    width: 80%; /* Largeur à 80% */
    max-width: 80%; /* Limite la largeur maximale à 80% */
    max-height: 150vh; /* Augmente la hauteur maximale à 150vh */
    padding: 15px; /* Réduction du padding */
    padding: 20px; /* Réduction du padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .video-frame {
    width: 100%; /* Utilisez 100% pour que l'iframe prenne toute la largeur disponible */
    height: 400px; /* Définissez une hauteur fixe pour les petits écrans */
    border: none; /* Supprimez la bordure si nécessaire */
  }
  .popup-content small-text {
    font-size: 0.8rem; /* Ajustez la taille de la police pour qu'elle soit similaire à <p> */
    line-height: 1.3; /* Ajustez la hauteur de ligne pour qu'elle soit similaire à <p> */
    margin-top: 20px; /* Ajoutez une marge en haut pour séparer le texte de l'image */
  }
}

/* Styles pour les grands écrans */
@media (min-width: 769px) {

  .popup-content small-text {
    font-size: 1rem; /* Ajustez la taille de la police pour qu'elle soit similaire à <p> */
    line-height: 1.5; /* Ajustez la hauteur de ligne pour qu'elle soit similaire à <p> */
  }
  .popup-content {
    width: 60%; /* Réduisez la largeur à 60% */
    max-width: 60%; /* Limitez la largeur maximale à 60% */
    max-height: 90vh; /* Limitez la hauteur maximale à 90vh */
    padding: 20px; /* Gardez le padding à 20px */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .video-frame {
    width: 100%; /* Utilisez 100% pour que l'iframe prenne toute la largeur disponible */
    height: 500px; /* Réduisez la hauteur fixe pour les grands écrans */
    border: none; /* Supprimez la bordure si nécessaire */
  }
}
