.scroll-section {
  text-align: center;
  overflow-x: hidden; /* Empêche le débordement horizontal */
}


.sound-examples {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px; /* Espace entre les vignettes */
}

.sound-example {
  position: relative;
  text-align: center;
  cursor: pointer; /* Change le curseur en une main pour les éléments cliquables */
  transition: transform 0.3s ease; /* Ajoute une transition pour la propriété transform */
  margin-bottom: 20px; /* Espace sous chaque vignette */
}

.sound-example.hovered {
  transform: scale(1.05);
}

.thumbnail {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  transition: filter 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Ajoute une transition pour la propriété transform */
}

.thumbnail.playing {
  filter: brightness(0.5); /* Assombrit l'image lorsque l'audio est en cours de lecture */
  animation: shadowPulse 3s infinite alternate; /* Ajoute l'animation de l'ombre */
  transform: scale(1.1); /* Grossit légèrement la vignette lorsqu'elle est en lecture */
}

.title {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.sound-example.playing .title,
.sound-example.hovered .title {
  opacity: 1; /* Rendre visible si en lecture ou survolé */
}


.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sound-example:hover .icon,
  .sound-example.playing .icon {
  opacity: 1;
}

/* Animation pour l'ombre pulsante */
@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: translateY(0);
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
    transform: translateY(-3px);
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transform: translateY(0);
  }
}






/* Media queries pour ajuster les vignettes sur les petits écrans */
@media (max-width: 1200px) {
  .sound-examples {
    grid-template-columns: repeat(3, 2fr); /* 3 colonnes pour les écrans de largeur maximale de 1200px */
  }
}

@media (max-width: 768px) {
  .sound-examples {
    grid-template-columns: repeat(2, 2fr); /* 2 colonnes pour les écrans de largeur maximale de 768px */
  }
  .thumbnail {
    width: 140px; /* Réduit encore la taille des vignettes */
    height: 140px; /* Réduit encore la taille des vignettes */
  }
}
@media (max-width: 768px) {
  .sound-examples {
    grid-template-columns: repeat(2, 2fr);
  }
  
  .thumbnail {
    width: 140px;
    height: 140px;
  }

  .title {
    font-size: 0.8rem; /* Réduit la taille de la police du titre */
    padding: 2px; /* Ajustez le padding si nécessaire */
  }
}