/* Styles généraux pour les petits écrans */
@media (max-width: 768px) {
  
  .App,
  .material-section,
  .presentation-section,
  .experience-section,
  .media-section,
  .sound-example,
  .material-column,
  .scroll-section,
  .media-gallery,
  .media-item,
  .scroll-text,
  .video-frame,
  .audio-frame,
  .popup-content {
    width: 100%;
  }




  .scroll-section h1,
  .scroll-section h2,
  .scroll-section h3,
  .media-title,
  .text-left,
  .sections h2,
  .popup-content p {
    text-align: center;
  }

  .background-photo,
  .video-frame,
  .audio-frame,
  .media-item img,
  .popup-content img {
    height: auto;
  }

  .background-photo,
  .media-item img,
  .popup-content img {
    width: 100%;
  }

  .white-band,
  .sections {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
  }

  .white-band {
    padding: 10px;
    margin: 0 auto;
  }

  .text-left {
    margin-bottom: 10px;
  }

  .text-left h1 {
    font-size: 1.5rem;
  }

  .text-left h2 {
    font-size: 1.2rem;
  }

  .sections {
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  .sections h2 {
    margin: 5px 0;
    font-size: 1rem;
    cursor: pointer;
  }

  .social-icons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .social-icons img {
    width: 20px;
  }

  .material-column {
    margin: 10px 0;
  }

  .material-column h2 {
    padding-bottom: 5px;
    margin: 10px 0;
  }

  .material-column p {
    margin: 2px 0;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .material-column p:hover {
    transform: scale(1.1);
  }



  .media-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .media-item:hover img {
    transform: scale(1.1);
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .popup-content {
    max-width: 90%;
    max-height: 90%;
  }

  .experience-marge-bas,
  .media-title {
    margin-bottom: 70px;
  }

  .scroll-text {
    margin: 0 20px;
    max-width: calc(100% - 40px);
    line-height: 1.63;
    font-size: 1.1rem;
  }
}

/* Styles pour les écrans moyens */
@media (max-width: 768px) {
  .background-photo {
    height: 70vh;
  }

  .white-band {
    padding: 10px;
  }

  .sections {
    flex-direction: column;
    align-items: center;
  }

  .social-icons {
    justify-content: center;
  }

  .scroll-text {
    font-size: 1.1rem;
  }
}

/* Modes horizontaux pour tablettes et téléphones */
@media (orientation: landscape) and (max-width: 1000px) {
  .white-band {
    padding: 3px 5px;
  }

  .text-left h1 {
    font-size: 1.5rem;
  }

  .text-left h2 {
    font-size: 1rem;
  }
}