/* In MediaSection.css */
.custom-link {
  display: inline-block;
  color: black;
  text-decoration: none;
  transition: transform 0.2s; /* Smooth transition */
}

.custom-link:hover {
  transform: scale(1.07); /* Zoom in on hover */
}
.media-title {
  margin-bottom: 70px; /* Espace sous le titre */
}


.media-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 0 auto; /* Centre la galerie et empêche le débordement */
  max-width: 100%; /* Assure que la galerie ne dépasse pas la largeur de la fenêtre */
  box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
}

.media-item {
  flex: 1 1 calc(25% - 20px); /* Les éléments prennent 25% de la largeur moins l'espace du gap */
  max-width: calc(25% - 20px); /* Limite la taille maximale des éléments */
  position: relative;
  overflow: hidden;
  cursor: pointer; /* Change le curseur en une main pour les éléments cliquables */
}

.media-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 10px; /* Ajoute des bords arrondis aux images */
}

.media-item:hover img {
  transform: scale(1.1);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(202, 202, 202, 0.5); /* Changez la couleur de fond à transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 1s ease, transform 1s ease;
}

.popup-content {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content img {
  width: auto;
  height: auto;
  max-width: 75%;
  max-height: 75%;
  border-radius: 10px;
  
}

/* Media queries pour ajuster les vignettes sur les petits écrans */
@media (max-width: 1200px) {
  .media-item {
    flex: 1 1 calc(33.33% - 20px); /* Les éléments prennent 33.33% de la largeur moins l'espace du gap */
    max-width: calc(33.33% - 20px); /* Limite la taille maximale des éléments */
  }
}

@media (max-width: 768px) {
  .media-item {
    flex: 1 1 calc(100% - 10px); /* Les éléments prennent 100% de la largeur moins l'espace du gap */
    max-width: calc(50% - 10px); /* Limite la taille maximale des éléments */
  }
  .popup-content img {
    max-width: 100%;
    max-height: 100%; 
  }
  .popup-content {
    width: 100%; /* Réduisez la largeur à 60% */
    max-width: 100%; /* Limitez la largeur maximale à 60% */
    padding: 15px; /* Gardez le padding à 20px */
  }
}
