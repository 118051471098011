

.dropdown-title {
  text-align: center;
  padding-bottom: 5px;
  cursor: pointer; /* Indique que c'est cliquable */
  user-select: none; /* Empêche la sélection du texte */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dropdown-content {
  max-height: 0; /* Hauteur maximale initiale */
  opacity: 0; /* Opacité initiale */
  overflow: hidden; /* Cache le contenu qui dépasse */
  transition: max-height 0.3s ease, opacity 0.5s ease; /* Transition pour un déroulement lent */
}

.dropdown-content.open {
  max-height: 500px; /* Hauteur maximale suffisante pour afficher le contenu */
  opacity: 1; /* Opacité pleine */
  transition: max-height 0.3s ease, opacity 0.5s ease; /* Transition pour un déroulement lent */
}

/* Section Expérience */
.experience-section {
  display: flex;
  flex-direction: column; /* Disposer les colonnes verticalement */
  justify-content: space-around;
  flex-wrap: wrap;
}

.experience-column {
  flex: 1;
  min-width: 150px;
  margin: 1px;
  display: flex;
  flex-direction: column; /* Assurez-vous que les éléments restent disposés verticalement */
  align-items: center; /* Centre les éléments horizontalement */
}

.experience-column h2 {
  text-align: center;
  display: inline-block; /* Pour centrer la bordure */
  width: 100%; /* Ajustez la largeur selon vos besoins */
  transition: transform 0.3s ease; /* Ajoute une transition pour un effet de zoom fluide */
}

.experience-column h2:hover {
  transform: scale(1.1); /* Effet de zoom sur le survol */
}

.experience-column p {
  margin: 2px 0; /* Réduisez la marge pour les paragraphes */
  padding-bottom: 2px; /* Réduisez l'espace sous la ligne */
  cursor: pointer; /* Change le curseur en une main pour les éléments cliquables */
  transition: transform 0.3s ease; /* Ajoute une transition pour l'effet de grossissement */
  display: inline-block; /* Pour centrer la bordure */
  width: 100%; /* Ajustez la largeur selon vos besoins */
  text-align: center; /* Centre le texte */
}

.experience-column p:hover {
  transform: scale(1.1); /* Grossit légèrement le texte lorsqu'on le survole */
}
