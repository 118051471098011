body {
  margin: 0;
  font-family: 'Lora', serif; /* Appliquer Lora à tout le texte */
  background-color: #ffffff; /* Fond blanc */
  color: #333; /* Couleur du texte */
  overflow-x: hidden; /* Évitez le débordement horizontal */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


a:focus {
    outline: none;
}

