/* Styles pour le lien avec effet de zoom */
.zoom-link {
  color: #000000c9; /* Couleur du texte */
  transition: transform 0.3s ease; /* Animation fluide pour le zoom */
  text-decoration: underline; /* Ajoute le soulignement par défaut */
  text-decoration-thickness: 0.6px; /* Définit l'épaisseur du soulignement */
  text-underline-offset: 3px; /* Définit l'espacement entre le soulignement et le texte */
  display: inline-block; /* Permet à transform de fonctionner correctement */
  cursor: pointer; /* Change le curseur en pointeur */
}

.zoom-link:hover {
  transform: scale(1.04); /* Applique un zoom de 10% au survol */
}


/* Styles généraux pour la section de présentation */
.presentation-title {
  margin-bottom: 50px; /* Espace sous le titre */
  margin-top: 50px;
}

.text-column {
    max-width: none;
    margin: 0 auto;
}
.scroll-section {
  background-color: white;
  margin: 0; /* Assurez-vous qu'il n'y a pas de marge */
  box-sizing: border-box; /* Pour inclure le padding dans la largeur */
}
.scroll-text {
  width: 80%; /* Prendre 80% de la largeur de .text-column */
  margin: 0 auto; /* Centre la colonne horizontalement */
  max-width: calc(100% - 40px); /* Limite la largeur maximale pour les écrans plus grands */
  line-height: 1.6; /* Hauteur de ligne pour une meilleure lisibilité */
  font-size: 1.3rem; /* Taille de police pour le texte petit */
}

/* Styles pour les tablettes */
@media (max-width: 768px) {
  .text-column {
    width: 100%; /* Augmente la largeur sur les petits écrans */
  }
  .scroll-text {
    font-size: 1.1rem; /* Ajuster la taille du texte pour les tablettes */
    text-align: justify; /* Justifier le texte */
    hyphens: auto;
    text-justify: inter-word; /* Justifie les mots en ajustant l'espacement entre eux */
    word-spacing: 0.05em; /* Ajuste l'espacement entre les mots */
    letter-spacing: 0.5px; /* Ajuste l'espacement entre les lettres */
}
  }
/* Styles pour les écrans moyens */
@media (max-width: 1200px) {
  .scroll-text {
    font-size: 1.2rem; /* Ajuster la taille du texte pour les écrans moyens */
  }
}